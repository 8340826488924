<template>
  <div>
    <el-table :data="tableData" v-loading="tableLoading">
      <el-table-column label="时间" prop="createTime" width="180"></el-table-column>
      <el-table-column label="序列号" prop="serialNumber"></el-table-column>
      <el-table-column label="X1" prop="xueXian_Ball1X"></el-table-column>
      <el-table-column label="Y1" prop="xueXian_Ball1Y"></el-table-column>
      <el-table-column label="线长1" prop="xueXian_Ball1ChangDu"></el-table-column>
      <el-table-column label="X2" prop="xueXian_Ball2X"></el-table-column>
      <el-table-column label="Y2" prop="xueXian_Ball2Y"></el-table-column>
      <el-table-column label="线长2" prop="xueXian_Ball2ChangDu"></el-table-column>
      <el-table-column label="颜色阀值" prop="xueXian_YSFZ"></el-table-column>
      <el-table-column label="判断条件" prop="xueXian_PDTJ"></el-table-column>
      <el-table-column label="查看">
        <template slot-scope="scope">
          <el-button @click="handleDownload(scope.row)" type="text" size="small">下载</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="getList" background :total="total" :page-size="pageSize" :current-page.sync="currentPage"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      tableData: null,
      pageSize: 10,
      currentPage: 1,
      total: 0,
      tableLoading: false,
    };
  },
  methods: {
    getFileName(path) {
      var pos1 = path.lastIndexOf("/");
      var pos2 = path.lastIndexOf("\\");
      var pos = Math.max(pos1, pos2);
      if (pos < 0) return path;
      else return path.substring(pos + 1);
    },
    handleDownload(row) {
      let that = this;
      open(
        "http://121.5.146.247/download/rhd/avi/" +
          that.getFileName(row.filePath)
      );
    },
    getList() {
      let that = this;
      that.tableLoading = true;
      that.axios1
        .post("RHD_ShiPinXinXi/GetList", {
          onlyText: "",
          currentPage: that.currentPage,
          pageSize: that.pageSize,
        })
        .then(function (response) {
          that.total = response.data.data.total;
          that.tableData = response.data.data.data;
          that.tableLoading = false;
        });
    },
  },
  mounted() {
    let that = this;
    that.getList();
  },
};
</script>

<style>
</style>